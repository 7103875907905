import React from "react";
import ReactHlsPlayer from 'react-hls-player';
import './video.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react';
import NhlGamesContext from './context/Context';

// <HLSPlayer trigger={play} setPlaying={setPlay} video={videourl} toggle={toggle}/>
function HLSPlayer(props) {

  const {toggle, play, setPlay, videourl} = useContext(NhlGamesContext)

      return (play) ? (
    <div className='popup' >
      <div className="video-overlay" onClick={() => setPlay(!play)}></div>
    <div className="video-player">
      
    <FontAwesomeIcon onClick={() => setPlay(!play)} className='close-btn' icon={faXmark} />
    
    <ReactHlsPlayer
    className={`${toggle ? "" : "novid"}`}
    src={videourl}
    autoPlay={true}
    controls={true}
    width="100%"
    height="auto"
    //poster={videourl.poster}
    hlsConfig={{
      startLevel: 8,
      enableWebVTT: false,
    }}
    />
    </div></div>
      ): "";

}

export default HLSPlayer