import React from 'react'
import {FaStar, FaPlay, FaHockeyPuck} from 'react-icons/fa'
import ListOfFins from "./ListofFins.json"
import ListOfSwedes from "./ListOfSwedes.json"
import { useContext } from 'react';
import { Link } from 'react-router-dom'
import NhlGamesContext from './context/Context';

function SingleGame({games}) {
    const {toggle, wantfins, wantswedes, favorites, startPlay, ToFavs} = useContext(NhlGamesContext)


    const wanted_fin = wantfins ? ListOfFins : []
    const wanted_swe = wantswedes ? ListOfSwedes : []
    const wantedIds = [...wanted_fin, ...wanted_swe]

    const handleRecapPlay = async (id, isCondensed) => {
      const videoUrl = await fetchHighlighVideourl(id, isCondensed);
  
      if (videoUrl == null) return;
      startPlay(videoUrl);
    };

    const PlaySingleGoal = async (period, time, id) => {

      const game = await fetchAllVids(id)
      if (game == null) return
      const goal = await FindGoalByPeriodTime(period, time, game)
      if (goal == null) return
      const video_url = await fetchHsingle(goal)
      if (video_url == null) return
      startPlay(video_url)
    };


    function SingleScore({toggle, score, winning}) {
        return (toggle) ? (
        <p className={`team-score ${winning ? "winning" : ""}`}>{score}</p>
        ): ''
        
      }

      function getTeamAbbreviation(teamId) {
        const teamMap = {
            1: "NJD",
            2: "NYI",
            3: "NYR",
            4: "PHI",
            5: "PIT",
            6: "BOS",
            7: "BUF",
            8: "MTL",
            9: "OTT",
            10: "TOR",
            12: "CAR",
            13: "FLA",
            14: "TBL",
            15: "WSH",
            16: "CHI",
            17: "DET",
            18: "NSH",
            19: "STL",
            20: "CGY",
            21: "COL",
            22: "EDM",
            23: "VAN",
            24: "ANA",
            25: "DAL",
            26: "LAK",
            28: "SJS",
            29: "CBJ",
            30: "MIN",
            52: "WPG",
            53: "ARI",
            54: "VGK",
            55: "SEA",
        };
      
        return teamMap[teamId] || "Unknown";
      }

    function FindGoalByPeriodTime(period, time, game) {
      for (const goal of game.summary.scoring[period-1].goals) {
        if(goal.timeInPeriod === time) {
          return goal.highlightClip
        }
      }
        return null
    }

    function SingleGoal({scoringplay, game}) {
      
          const playergoals = scoringplay.players.filter(player => !player.playerType.includes("Goalie")).map(players => players.player)


         /*  function GetPB(id, game) {
            let ret_obj = null
            if(!game) {return null}
            if(!game.content) {return null}
            if(!game.content.highlights) {return null}
            if(!game.content.highlights.scoreboard) {return null}
            game.content.highlights.scoreboard.items.forEach(element => {

              element.keywords.forEach((el, i) => {
                if(el.type == 'statsEventId' && el.value == id) {
                  ret_obj = element.playbacks[9].url
                  }
              })
                
              });
              return ret_obj
          }


          const vid_url = GetPB(scoringplay.about.eventId, game) */



          const goal_time = parseFloat(scoringplay.about.periodTime.replace(':','.')) + (scoringplay.about.period > 1 ? (scoringplay.about.period-1)*20 : null)
          //if (goal_time < 10) { console.log(0+goal_time.toString())}
          const PlayerIDs = playergoals.map(pid => pid.id)
          const score = scoringplay.about.goals.home + ' - ' + scoringplay.about.goals.away
          const IsFinn = wantedIds.some( ai => PlayerIDs.includes(ai) )
          return <><div className={`goal ${IsFinn ? "Highlight" : ""}`}>
            <span className='goal-time'>{scoringplay.about.ordinalNum === 'SO' ? 'SO' : parseFloat(goal_time).toFixed(2)}</span>
            <FaHockeyPuck className='react-icons'/> {playergoals[0].fullName} {playergoals.length > 1 && '('+playergoals.splice(1,2).map(p => p.fullName).join(", ")+')'}
            <span className='curr-score'>{scoringplay.about.ordinalNum !== 'SO' ? score : null}</span> </div> 
            <div className='vid_link'>{scoringplay.about.ordinalNum !== 'SO' && <FaPlay className='react-icons' onClick={() => PlaySingleGoal(scoringplay.about.period, scoringplay.about.periodTime, game.gamePk)} />}</div>
            </>
    }


    return (
        games.map((game) => { 
          
          const isRecap = game.status.abstractGameState == "Final"
          const timestamp = new Date(game.gameDate)
     
          return (
          <div 
         
          className={`singlegame ${!toggle ? "minified" : ""}`} key={game.gamePk}>
            <div className="home">

            <span className='fav'><FaStar onClick={() => ToFavs(game.teams.home.team.id)} className={`${favorites.includes(game.teams.home.team.id.toString()) ? "checked react-icons" : "react-icons"}`}/></span>
                <Link to={'/teams/'+game.teams.home.team.id} >
              <img className='team-image' src={"https://assets.nhle.com/logos/nhl/svg/"+getTeamAbbreviation(game.teams.home.team.id)+"_dark.svg"} alt={game.teams.home.team.name} />
              </Link>
              <Link style={{ textDecoration: 'none', color: '#c9d1d9' }}to={'/teams/'+game.teams.home.team.id} ><p className="team-name">{game.teams.home.team.name}</p></Link>
              {game.status.statusCode !== "1" ?
              <SingleScore toggle={toggle} score={game.teams.home.score}  winning={(game.teams.home.score > game.teams.away.score) ? true : false} />:null}
              
            </div>
            <div className="away">
            <span className='fav'><FaStar onClick={() => ToFavs(game.teams.away.team.id)} className={`${favorites.includes(game.teams.away.team.id.toString()) ? "checked react-icons" : "react-icons"}`} /></span>
            <Link to={'/teams/'+game.teams.away.team.id} >
              <img className='team-image' src={"https://assets.nhle.com/logos/nhl/svg/"+getTeamAbbreviation(game.teams.away.team.id)+"_dark.svg"} alt={game.teams.away.team.name} />
              </Link>
              <Link style={{ textDecoration: 'none', color: '#c9d1d9' }}to={'/teams/'+game.teams.away.team.id} ><p className="team-name">{game.teams.away.team.name}</p></Link>
              {game.status.statusCode !== "1" ?
              <SingleScore toggle={toggle} score={game.teams.away.score} winning={(game.teams.home.score < game.teams.away.score) ? true : false}/> : <p className='starttime'>Starts at {timestamp.toLocaleTimeString('it-IT',{ hour: '2-digit', minute: '2-digit' })}</p>}
            </div>
            {isRecap && <>
            <div className='recaps'>
            <span className='recap'>Extended Highlights</span> <span className='recap_link'><FaPlay className='react-icons' onClick={() => handleRecapPlay(game.gamePk, true)} /></span>
            <span className='recap'>Recap</span><span className='recap_link'><FaPlay className='react-icons' onClick={() => handleRecapPlay(game.gamePk, false)}  /></span></div></>}

                   
            
            <div className='goals'>
            {toggle && game.scoringPlays.map(scoringplay => <SingleGoal scoringplay={scoringplay} game={game} key={scoringplay.about.eventId}/>)}
            </div>
          </div>
        )})
      )
}

const fetchHighlighVideo = async (id, isCondensed) => {
  try {
    const response = await fetch(`https://hehe.nhlrecap.com/?https://api-web.nhle.com/v1/gamecenter/${id}/landing` , {

    headers: {

    'x-cors-api-key': 'temp_ec89abfc07d1cd3d760c3e4397975d32'

    }

  });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const threeMinRecap = isCondensed ? data.summary.gameVideo.condensedGame : data.summary.gameVideo.threeMinRecap;

    console.log(threeMinRecap)
    return threeMinRecap;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

const fetchAllVids = async (id) => {
  try {
    const response = await fetch(`https://hehe.nhlrecap.com/?https://api-web.nhle.com/v1/gamecenter/${id}/landing` , {

    headers: {

    'x-cors-api-key': 'temp_ec89abfc07d1cd3d760c3e4397975d32'

    }

  });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};


const fetchHighlighVideourl = async (id, isCondensed) => {

  const video_id = await fetchHighlighVideo(id, isCondensed)

  if (video_id == null) return null
  try {
    const response = await fetch(`https://hehe.nhlrecap.com/?https://edge.api.brightcove.com/playback/v1/accounts/6415718365001/videos/${video_id}`, {
      headers: {
        'authority': 'edge.api.brightcove.com',
        'accept': 'application/json;pk=BCpkADawqM3l37Vq8trLJ95vVwxubXYZXYglAopEZXQTHTWX3YdalyF9xmkuknxjBgiMYwt8VZ_OZ1jAjYxz_yzuNh_cjC3uOaMspVTD-hZfNUHtNnBnhVD0Gmsih8TBF8QlQFXiCQM3W_u4ydJ1qK2Rx8ZutCUg3PHb7Q',
        'accept-language': 'fi-FI,fi;q=0.9,en-US;q=0.8,en;q=0.7,ru;q=0.6',
        'origin': 'https://players.brightcove.net',
        'referer': 'https://players.brightcove.net/',
        'sec-ch-ua': '"Chromium";v="118", "Google Chrome";v="118", "Not=A?Brand";v="99"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"Windows"',
        'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
        'x-cors-api-key': 'temp_ec89abfc07d1cd3d760c3e4397975d32'
      }
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const threeMinRecap = await data.sources[1].src;
    return threeMinRecap;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

const fetchHsingle = async (id) => {

  try {
    const response = await fetch(`https://hehe.nhlrecap.com/?https://edge.api.brightcove.com/playback/v1/accounts/6415718365001/videos/${id}`, {
      headers: {
        'authority': 'edge.api.brightcove.com',
        'accept': 'application/json;pk=BCpkADawqM3l37Vq8trLJ95vVwxubXYZXYglAopEZXQTHTWX3YdalyF9xmkuknxjBgiMYwt8VZ_OZ1jAjYxz_yzuNh_cjC3uOaMspVTD-hZfNUHtNnBnhVD0Gmsih8TBF8QlQFXiCQM3W_u4ydJ1qK2Rx8ZutCUg3PHb7Q',
        'accept-language': 'fi-FI,fi;q=0.9,en-US;q=0.8,en;q=0.7,ru;q=0.6',
        'origin': 'https://players.brightcove.net',
        'referer': 'https://players.brightcove.net/',
        'sec-ch-ua': '"Chromium";v="118", "Google Chrome";v="118", "Not=A?Brand";v="99"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"Windows"',
        'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
        'x-cors-api-key': 'temp_ec89abfc07d1cd3d760c3e4397975d32'
      }
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const threeMinRecap = await data.sources[1].src;
    return threeMinRecap;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export default SingleGame