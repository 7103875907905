import React from 'react'
import TeamSpecific from "./TeamSpecific";
import {useParams} from 'react-router-dom'
import teams from './teams.json'


function TeamSec() {
    const params = useParams()

   if (teams.toString().includes(params.id.toString())) {

  return (<TeamSpecific id={params.id} />) 
    }

  else {
    return (
        <>
        <main style={{ padding: "1rem", textAlign:'center' }}>
          <p>There's nothing here!</p>
        </main>
        </>
    )
}
}

export default TeamSec