import React from 'react'
import { Link } from 'react-router-dom'
import data from './Data.json'
import { useContext } from 'react';
import NhlGamesContext from './context/Context';
import {FaStar} from 'react-icons/fa'

function getTeamAbbreviation(teamId) {
  const teamMap = {
      1: "NJD",
      2: "NYI",
      3: "NYR",
      4: "PHI",
      5: "PIT",
      6: "BOS",
      7: "BUF",
      8: "MTL",
      9: "OTT",
      10: "TOR",
      12: "CAR",
      13: "FLA",
      14: "TBL",
      15: "WSH",
      16: "CHI",
      17: "DET",
      18: "NSH",
      19: "STL",
      20: "CGY",
      21: "COL",
      22: "EDM",
      23: "VAN",
      24: "ANA",
      25: "DAL",
      26: "LAK",
      28: "SJS",
      29: "CBJ",
      30: "MIN",
      52: "WPG",
      53: "ARI",
      54: "VGK",
      55: "SEA",
  };

  return teamMap[teamId] || "Unknown";
}
   
function AllTeams() {
    const {favorites, ToFavs} = useContext(NhlGamesContext)
    const todisplay = data.data.map((team) => {
        return (
            <div className="team" key={team.teamId}>
                <div className="team-and-link">
                <span className='fav'><FaStar onClick={() => ToFavs(team.teamId)} className={`${favorites.includes(team.teamId.toString()) ? "checked react-icons" : "react-icons"}`}/></span>
            <Link to={'/teams/'+team.teamId} >
              <img className='team-image' src={"https://assets.nhle.com/logos/nhl/svg/"+getTeamAbbreviation(team.teamId)+"_dark.svg"} alt={team.teamId} />
              </Link>
              </div>
              <Link style={{ textDecoration: 'none', color: '#c9d1d9' }}to={'/team/'+team.teamId} ><p className="team-name">{team.teamFullName}</p></Link>
            </div>
        )

    })
  return (
    <div 
    className="allgames allteams">
   {todisplay}
 </div>
  )
}

export default AllTeams