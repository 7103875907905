import React from 'react'
import { useState, useEffect } from "react";
import { useContext } from 'react';
import NhlGamesContext from './context/Context';
import SingleGame from './SingleGame';


function NHLGames() {

    const {favorites} = useContext(NhlGamesContext)
    const [NHLGames, setNHLGames] = useState([])
    const [display, setDisplay] = useState(false) 
    
    useEffect(() => {
      GetNHLGames();
    }, []);



    const GetNHLGames = async () => {
      var dt = new Date();
      dt.setDate( dt.getDate() - 0 );

      const date = dt.toJSON().slice(0,10);
      dt.setDate( dt.getDate() - 10 );
      const date2 = dt.toJSON().slice(0,10);
      const response = await fetch(
        `https://statsapi.web.nhl.com/api/v1/schedule?startDate=${date2}&endDate=${date}&hydrate=game(content(media(epg),highlights(scoreboard))),scoringplays&site=en_nhlNORDIC&teamId=&gameType=&timecode=`
      );
      const data = await response.json();
      setNHLGames(data.dates.reverse());
      setDisplay(true)
  
    }


    function SortByFavorite(data) {
      var includes = []
      var notinclues = []
      /* const PlayerIDs = playergoals.map(pid => pid.id)
      const score = scoringplay.about.goals.home + ' - ' + scoringplay.about.goals.away
      const IsFinn = wantedIds.some( ai => PlayerIDs.includes(ai) ) */
      
        data.forEach(game => {
          //console.log(game.teams.away.team.id)
          const TeamIDs = [game.teams.away.team.id.toString(), game.teams.home.team.id.toString()]

          const IsFav = favorites.some(ai => TeamIDs.includes(ai))

          IsFav ? includes.push(game) : notinclues.push(game)
        }
      )
      const sortedData = includes.concat(notinclues)
      return sortedData
    }

    const games = NHLGames.map(game => {
      let ts = new Date(game.date)
      const timestamp = new Date(game.games[0].gameDate)
      const nowstamp = new Date(Date.now())
      nowstamp.setHours(nowstamp.getHours() +4);
      if (nowstamp > timestamp)
      {
      return (
        <div key={game.date} >
        <h2 style={{margin: "1em"}}>{ts.toLocaleDateString()}</h2>
        <div key={game.date} className='game-day'>
        <SingleGame games={SortByFavorite(game.games)} key={game.date} />
        </div>
        </div>
      )
      }
      else {
        return <React.Fragment key={game.date}></React.Fragment>
      }
    })

    return (
      <div 
         className="allgames">
        {display 
        ? games
        :    <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>}
      </div>
    )
    
}

export default NHLGames