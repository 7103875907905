import NHLGames from "./GetNHLGames";
import HLSPlayer from "./HLSPlayer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavMenu from "./NavMenu";
import { NhlGamesProvider } from "./context/Context";
import './App.css'
import TeamSec from "./TeamSec";
import AllTeams from "./Teams.jsx";
import Favs from "./Favs";

function App() {
return (
  <NhlGamesProvider>
    <Router>
      <div>
      <NavMenu />
        <Routes>
        <Route path="teams">
          <Route path=":id" element={<TeamSec  />} />
          <Route index element={<AllTeams />} />
        </Route>
        <Route index element={
            <NHLGames />
          } />
            <Route
              path="*"
              element={
                <>
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
                </>
              }
            />
            <Route exact path="favs" element={<Favs />} />
        </Routes>
        <HLSPlayer />
      </div>
    </Router>
  </NhlGamesProvider>
)

}

export default App;
