import React from 'react'

import { useContext } from 'react';
import NhlGamesContext from './context/Context';
import TeamSpecific from './TeamSpecific';

   

function Favs() {
    const {favorites} = useContext(NhlGamesContext)

    if (favorites.length > 0) {

        return (<TeamSpecific id={favorites.toString()} />) 
          }
      
        else {
          return (
              <>
              <main style={{ padding: "1rem", textAlign:'center' }}>
                <p>Add some Favorites first!</p>
              </main>
              </>
          )
      }
}

export default Favs