import React from 'react'
import ReactCountryFlag from "react-country-flag"
import { Divide as Hamburger } from 'hamburger-react'
import { useLocalStorage } from './localStorage'
import { useContext } from 'react';
import NhlGamesContext from './context/Context';
import {AiFillHome} from 'react-icons/ai'
import {MdOutlineFavorite} from 'react-icons/md'
import {GiHockey} from 'react-icons/gi'
import { NavLink } from 'react-router-dom'
import './Nav.css'

function NavMenu() {

  
    const {toggle, setToggle, setWantfins, setWantswedes, wantfins, wantswedes} = useContext(NhlGamesContext)
    const [isOpen, setOpen] = useLocalStorage("hamburger", false);

  return (
    <div className="navbar">
          <div className="controls">
            <Hamburger toggled={isOpen} toggle={setOpen} 
                color="rgb(81, 160, 134)"
            />
            <div className="show-score-inner"style={!isOpen ? {display: 'none'}:{}}>
                <div className='show-score'>
                  <h3>Show score</h3>
                  <label className="switch">
                  <input type="checkbox"
                  checked={toggle}
                  onChange={() => setToggle(!toggle)}
                  />
                  <span className="slider round"></span>
                  </label>
                  </div>
                  <div className='show-highlight'>
                  <h3><ReactCountryFlag countryCode="FI" 
                    style={{
                      fontSize: '1.5em'
                    }}
                    svg /></h3>
                
                  <label className="switch">
                  <input type="checkbox"
                  checked={wantfins}
                  onChange={() => setWantfins(!wantfins)}
                  />
                  <span className="slider round"></span>
                  </label>
                  </div>
                  <div className='show-highlight'>
                  <h3><ReactCountryFlag countryCode="SE" 
                  style={{
                    fontSize: '1.5em'
                  }}
                  svg /></h3>
                  <label className="switch">
                  <input type="checkbox"
                  checked={wantswedes}
                  onChange={() => setWantswedes(!wantswedes)}
                  />
                  <span className="slider round"></span>
                </label>
            </div></div></div>
            <div className="site-links"style={isOpen ? {display: 'none'}:{}}>
            <NavLink 
              to={'/'} 
            >
            <AiFillHome className='nav-icons' />
            </NavLink>
            <NavLink to={'/favs'}>
            <MdOutlineFavorite className='nav-icons' />
            </NavLink>
            <NavLink to={'/teams'} >
            <GiHockey className='nav-icons' />
            </NavLink>
            </div>
      </div>
  )
}

export default NavMenu