import { createContext, useState } from "react";
import { useLocalStorage } from "../localStorage";

const NhlGamesContext = createContext()

export const NhlGamesProvider = ({children}) => {

    const [toggle, setToggle] = useLocalStorage("toggle", false);
    const [wantfins, setWantfins] = useLocalStorage("finss", false);
    const [wantswedes, setWantswedes] = useLocalStorage("swedes", false);
    const [favorites, setFavorites] = useLocalStorage("favorites", []);
    const [play, setPlay] = useState(false)
    const [videourl, setVideourl] = useState({})

    function startPlay(vid) {
        setVideourl(vid)
        setPlay(true)
      }

      function ToFavs(id) {
        const IsInList = favorites.includes(id.toString())
  
        if (IsInList) {
          const newFavList = favorites.filter((fav) => 
          fav !== id.toString())
          setFavorites(newFavList)
        }
        else {
          const newFavList = favorites.concat(id.toString())
          setFavorites(newFavList)
        }
      }
    return ( 
    <NhlGamesContext.Provider 
        value={{
            toggle,
            setToggle,
            wantfins,
            setWantfins,
            wantswedes,
            setWantswedes,
            favorites,
            setFavorites,
            play,
            setPlay,
            videourl,
            setVideourl,
            startPlay,
            ToFavs
        }}>

        {children}
    </NhlGamesContext.Provider>
    )
}

export default NhlGamesContext