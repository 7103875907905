import React, {useState, useEffect} from 'react'

import SingleGame from './SingleGame'

function TeamSpecific({id}) {
  const [NHLGames, setNHLGames] = useState([])
  const [display, setDisplay] = useState(false) 
  useEffect(() => {
    setDisplay(false)
    GetNHLGames();
  }, [id]);
  const GetNHLGames = async () => {
    var dt = new Date();
    const date = dt.toJSON().slice(0,10);
    dt.setDate( dt.getDate() - 20 );
    const date2 = dt.toJSON().slice(0,10);
    const response = await fetch(
      `https://statsapi.web.nhl.com/api/v1/schedule?startDate=${date2}&endDate=${date}&hydrate=game(content(media(epg),highlights(scoreboard))),scoringplays&site=en_nhlNORDIC&teamId=${id.toString()}&gameType=&timecode=`
    );
    const data = await response.json();
    setNHLGames(data.dates.reverse());
    setDisplay(true)

  }


    const games = NHLGames.map(game => {
      let ts = new Date(game.date)
      return (
        <div className='singlegame-game' key={game.date} >
        <h2 style={{margin: "1em", textAlign: 'center'}}>{ts.toLocaleDateString()}</h2>
        <div className="singledategame">
        <SingleGame games={game.games} key={game.date} />
        </div>
        </div>
      )
    })


    return (
      <div 
      className="allgames-team-spesific">
     {display 
     ? games
     :    <div className="spinner-container">
     <div className="loading-spinner">
     </div>
   </div>}
   </div>
    )

}

export default TeamSpecific